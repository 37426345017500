<template>
  <div>
    <SystemAdministratorsTable
      :userList="userList"
      :pageLoader="pageLoader"
      :error="error"
      type="admin"
    >
    </SystemAdministratorsTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_SYS_ADMINISTRATOR_LIST } from "@/core/services/store/core/user.module";
import SystemAdministratorsTable from "@/view/content/users/UserTable";

export default {
  name: "ViewSystemAdministrators",

  components: {
    SystemAdministratorsTable
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.$t("NAVIGATION.ROOT_MENU.USERS")}` }]);
    this.fetchSystemAdministrators();
  },

  methods: {
    fetchSystemAdministrators() {
      let payload = {
        params: {
          paginate: false,
          adminRole: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_SYS_ADMINISTRATOR_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the system administrators list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters(["userList"])
  }
};
</script>
